@font-face {
    font-family: 'Oddtype';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/Odd-Medium.eot');
    src: url('../fonts/Odd-Medium.eot') format('embedded-opentype'), url('../fonts/Odd-Medium.woff') format('woff'),
        url('../fonts/Odd-Medium.woff2') format('woff2'), url('../fonts/Odd-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Interstate';
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/Interstate-regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Interstate';
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/Interstate-bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Interstate';
    font-weight: 200;
    font-display: swap;
    src: url('../fonts/Interstate-light.ttf') format('truetype');
}

body {
    background-color: #fff;
    font-family: 'Interstate' !important;
    font-size: 16px;
    font-weight: 200;
    position: relative;
    -webkit-overflow-scrolling: touch;
    margin-left: -15px;
    margin-right: -15px;
}

input {
    font-family: 'Interstate' !important;
    font-weight: 400;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0.2;
    }
    40% {
        opacity: 0.4;
    }
    60% {
        opacity: 0.6;
    }
    80% {
        opacity: 0.8;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0.2;
    }
    40% {
        opacity: 0.4;
    }
    60% {
        opacity: 0.6;
    }
    80% {
        opacity: 0.8;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    20% {
        opacity: 0.8;
    }
    40% {
        opacity: 0.6;
    }
    60% {
        opacity: 0.4;
    }
    80% {
        opacity: 0.2;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    20% {
        opacity: 0.8;
    }
    40% {
        opacity: 0.6;
    }
    60% {
        opacity: 0.4;
    }
    80% {
        opacity: 0.2;
    }
    100% {
        opacity: 0;
    }
}
